@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';

.App {
  text-align: center;
}

.form-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.bg-color {
  background-color: black;
}

.App-header {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.item-center {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

.hover-cursor-clickable {
  cursor: pointer;
}

.ant-pro-menu-item {
  display: flex;
  align-items: center;
}

.ant-table-container {
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
}

.ant-table-container {
  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}

.ant-table-container {
  *::-webkit-scrollbar-corner {
    background-color: rgba(169, 169, 169, 0.068);
  }
}

.ant-table-container {
  *::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0.68);
    outline: 1px solid rgba(112, 128, 144, 0.589);
  }
}
div.ant-col.ant-form-item-control > div > div > span > span > span {
  padding-left: 0px;
  padding-right: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.ant-pro-sider-logo h1 {
  font-size: 16px !important;
}

.ant-drawer-body {
  padding: 0px !important;
  overflow: hidden !important;
}

.custom-drawer-body {
  padding: 24px !important;
  overflow: auto;
  height: 100%;
  width: 100%;
}

#logo > a > h1 {
  display: none !important;
}

.ant-pro-fixed-header.ant-pro-fixed-header-action.ant-pro-header-dark > div > div:nth-child(1) {
  display: none !important;
}

.ant-table-footer {
  height: 0;
  padding: 0px !important;
}

.bulk-action-container {
  position: absolute;
  bottom: -50px;
  left: 0;
}

@media (max-width: 768px) {
  .ant-table-footer {
    height: 100px;
    padding: 8px 8px !important;
  }

  .bulk-action-container {
    position: relative;
    bottom: 0;
    left: 20%;
    padding-top: 20px;
  }
}

.warning-btn {
  background-color: @orange-6 !important;
  border-color: @orange-6 !important;
  span > svg,
  span {
    color: @white;
  }
}

@primary-color: #3fc4ff;