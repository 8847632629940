.flex_center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-l-2 {
  padding-left: 4px;
}

.mb-4 {
  margin-bottom: 16px;
}

.fw {
  width: 100%;
}

.cover_center {
  width: 100%;
  height: 100%;
}

.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@primary-color: #3fc4ff;