.mb-10 {
  margin-bottom: 24px !important;
}

.submit-archive-time-btn {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
}

@media screen and (min-width: 768px) {
  .card-container {
    width: 60vw;
    margin: 0 auto !important;
  }
}

@primary-color: #3fc4ff;