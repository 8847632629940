.right-content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.right-content-btn {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .app-title {
    display: none;
  }
  .right-content-container {
    justify-content: flex-end;
  }
}

@primary-color: #3fc4ff;