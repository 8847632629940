.progress {
  display: flex;
  overflow: hidden;
  height: 6px;

  background-color: #ecf0f1;

  width: 100%;
}
.progress-bar {
  overflow: hidden;
  text-align: center;
  border-radius: inherit;
  background-color: dodgerblue;
}
.progress-bar-animated {
  position: relative;
}
.progress-bar-animated::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.7));
  transform: translateX(-100%);
  animation: progress-bar-shine 2s infinite;
}
@keyframes progress-bar-shine {
  to {
    transform: translateX(0);
    opacity: 0.1;
  }
}

@primary-color: #3fc4ff;